import {ClientProvider} from '@mantine/remix';
import {RemixBrowser, useLocation} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import * as React from 'react';
import {useEffect} from 'react';
import {registerPrismaDecimalTransformer, useMatches} from '~/features/serialization';
import {emotionCache} from './root';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import {hydrate} from 'react-dom';

Sentry.init({
  dsn: window.ENV.SENTRY_DSN,
  environment: window.ENV.APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches),
    }),
  ],
  release: window.ENV.APP_VERSION,
  tracesSampler: context => (context.location?.pathname?.startsWith('/healthcheck') ? 0 : 0.01),
  tunnel: '/proxy/errors',
});

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

registerPrismaDecimalTransformer();

function start() {
  hydrate(
    <React.StrictMode>
      <ClientProvider emotionCache={emotionCache}>
        <RemixBrowser />
      </ClientProvider>
    </React.StrictMode>,
    document
  );

  /**
   * This is preferred approach to hydration with react@18.
   * Keeping this here to use after upgrade to react@18 when full document hydration works.
   *
   * @link {https://github.com/facebook/react/issues/24430}
   * @link {https://github.com/remix-run/remix/issues/2947}
   */
  // React.startTransition(() => {
  //   hydrateRoot(
  //     document,
  //     <React.StrictMode>
  //       <ClientProvider emotionCache={emotionCache}>
  //         <RemixBrowser />
  //       </ClientProvider>
  //     </React.StrictMode>
  //   );
  // });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(start);
} else {
  window.setTimeout(start, 1);
}
